import React from "react"
import Layout from "../components/layout"
import "./index.css"

const VideoPage = () => {
  return (
    <>
      <Layout pageTitle="Video av vielsen!">
        <section>
          <iframe
            className="mx-auto"
            src="https://drive.google.com/file/d/1xM5DWlPLstwOsyxOJjIj0JrCW9kkalCk/preview"
            width="640"
            height="480"
          ></iframe>
          <p className="my-4">📷 Videoen er filmet av Marte Pritzlaff Njerve</p>
        </section>
      </Layout>
    </>
  )
}

export default VideoPage
